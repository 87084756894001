<template>
    <div
        class="ContentReset"
        v-html="cleanedBody"
    />
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
    body: string
}>()

const cleanedBody = computed(() => {
    // Regex for `<a` which is NOT followed by 0 or more non `>` characters and then `target="_blank"`
    const regularExpression = /<a(?![^>]*\btarget="_blank")/gi
    // Replace all matches with <a target="_blank"
    return props.body.replace(regularExpression, '<a target="_blank"')
})
</script>
